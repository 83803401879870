import React from "react";
import chevronDown from '../../assets/img/chevron-down.svg';
import { Form } from 'semantic-ui-react'
import { SketchPicker } from 'react-color';
import 'semantic-ui-css/semantic.min.css'
import axios from 'axios';

//import axios from 'axios';
import {useEffect, useState} from "react"
import $ from 'jquery';


const ajaxDelay = (function(){
    var ajax_timer = 0;
    return function(callback, ms){
      clearTimeout (ajax_timer);
      ajax_timer = setTimeout(callback, ms);
    };
})();



function SubMenuSettings({subMenu, hideSubMenu, settings, flexPageId, setSettings, fonts}) {



    const presetColors = ['#176B87','#64CCC5','#001C30','#ed1c24','#058dcf','#70bf5a', '#21543d', '#494A4B', '#ad423a', '#574545', '#a89255', '#ac1f2d', '#ffd920','#000000'];


    const [changes, setChanges] = useState([]);


    const font_weights      = [{text: '100',value:'100'},{text: '200', value: '200'},{text: '300', value: '300'},{text: '400', value: '400'},{text: '500', value: '500'},{text: '600', value: '600'},{text: '700', value: '700'},{text: '800', value: '800'},{text: '900', value: '900'}];
    const text_transform    = [{text: 'Default',value:''},{text: 'None', value: 'none'},{text: 'Lowercase', value: 'lowercase'},{text: 'Uppercase', value: 'uppercase'},{text: 'Capitalize', value: 'capitalize'}];

    useEffect( () => { 


        $(document).off('click.collapse','.crt-field-collapse-title');
        $(document).on('click.collapse', '.crt-field-collapse-title', function(){
            $(this).closest('.crt-field-collapse').toggleClass('open');
            $(this).closest('.crt-field-collapse').find('.crt-field-collapse-content').slideToggle('fast');
        });


        $(document).off('click.colorPicker','.settings-color-picker .color-picker-value');
        $(document).on('click.colorPicker', '.settings-color-picker .color-picker-value', function(){
            $(this).closest('.settings-color-picker').find('.color-picker-popover').show('fast')
        });


        $(document).off('click.closeColorPicker','.settings-color-picker .close-color-picker');
        $(document).on('click.closeColorPicker', '.settings-color-picker .close-color-picker', function(){
            $(this).closest('.settings-color-picker').find('.color-picker-popover').hide('fast')
        });


    }, []);


    const saveSettings = (newSettings) => {

        //this.props.loading();

        axios.post(process.env.REACT_APP_API_ENDPOINT + '/flex-save-settings/',{settings: newSettings, flex_page_id: flexPageId})

            .then(res => {

            })

            .catch((error) => {

            });

    }


    const replaceCSSPropertyValue = (cssString, propertyName, newValue) => {
        const regex = new RegExp("(" + propertyName + "\\s*:\\s*)([^;]+)");
        const replacedString = cssString.replace(regex, "$1" + newValue);
        return replacedString;
    }


    const hexIsLightColor = (color) => {
        const hex = color.replace('#', '');
        const c_r = parseInt(hex.substr(0, 2), 16);
        const c_g = parseInt(hex.substr(2, 2), 16);
        const c_b = parseInt(hex.substr(4, 2), 16);
        const brightness = ((c_r * 299) + (c_g * 587) + (c_b * 114)) / 1000;
        return brightness > 155;
    }


    const saveChangesToDB = (changes) => {


        let newSettings = settings;

        if(changes.selector === undefined){
            return ;
        }
        

        
        /**
         * Update the CSS in page head
         */

        let $css_style = $('#d6-flex-iframe').contents().find('#d6-block-css-inline-css');
        let globa_css = $css_style.text();


        


        let propertyName = '';

        if(changes.selector === 'body'){
            propertyName = '--site-'+(changes.prop==='color'?'text-':'')+(changes.prop.replace(/[A-Z]/g, match => '-' + match)).toLowerCase();
        }else if(changes.selector === 'button'){
            propertyName = '--site-button-'+(changes.prop.replace(/[A-Z]/g, match => '-' + match)).toLowerCase();
        }else{
            propertyName = '--site-heading-'+changes.selector+'-'+(changes.prop.replace(/[A-Z]/g, match => '-' + match)).toLowerCase();
        }



        let value = changes.value;


        if(value.trim() === ''){

            if(changes.selector === 'body'){
                value = changes.prevValue;//body props are required
            }else{
                value = 'unset';
            }
        }
        


        


        if(changes.prop === 'fontFamily'){
            let font = getTheFont(value);
            globa_css = replaceCSSPropertyValue(globa_css, propertyName, font.css);
        }else{
            globa_css = replaceCSSPropertyValue(globa_css, propertyName, value);
        }



        /**
         * There are elements that uses svg as background image, we need to replace its color 
         */
        
        if(changes.prop === 'primaryColor' || changes.prop === 'secondaryColor' || changes.prop === 'tertiaryColor'){

            let prevColorValue = changes.prevValue.replace('#','%23');
            var searchMask = prevColorValue;
            var regEx = new RegExp(searchMask, "ig");
            var replaceMask = changes.value.replace('#','%23');

            globa_css = globa_css.replace(regEx, replaceMask);
        
        }


        /**
         * For dark section
         */


        //console.log(settings)

        
        if(changes.prop === 'primaryColor' || changes.prop === 'secondaryColor'  || changes.prop === 'tertiaryColor' ){
            

            if(hexIsLightColor(value)){
                globa_css = replaceCSSPropertyValue(globa_css, '--site-dark-section-primary-color', value);    
                newSettings[changes.selector]['darkSectionPrimaryColor'] = value;
            } else if(hexIsLightColor(settings.body.secondaryColor)){
                globa_css = replaceCSSPropertyValue(globa_css, '--site-dark-section-primary-color', settings.body.secondaryColor);
                newSettings[changes.selector]['darkSectionPrimaryColor'] = settings.body.secondaryColor;
            } else if(hexIsLightColor(settings.body.tertiaryColor)){
                globa_css = replaceCSSPropertyValue(globa_css, '--site-dark-section-primary-color', settings.body.tertiaryColor);
                newSettings[changes.selector]['darkSectionPrimaryColor'] = settings.body.tertiaryColor;
            } else{
                globa_css = replaceCSSPropertyValue(globa_css, '--site-dark-section-primary-color', '#FFFFFF');
                newSettings[changes.selector]['darkSectionPrimaryColor'] = '#FFFFFF';
            }

        }


        

        /**
         * Update the head inline CSS
         */

        $('#d6-flex-iframe').contents().find('#d6-block-css-inline-css').text(globa_css);
        

        /**
         * 
         */


        
        newSettings[changes.selector][changes.prop] = value;




        ajaxDelay(function(){
            saveSettings(settings); 
        }, 1000 );

        //saveSettings(settings);
        setChanges({})
        setSettings(newSettings);

    }

    const getTheFont = (fontName) =>{


        let theFont = {};

        fonts.forEach(font => {
            if(font.value === fontName){
                theFont = font;
                return;
            }
            
        });

        return theFont;

    }

    const handleSettingsChange = (selector, prop, value, prevValue) => {


        /**
         * Add google font to head
         */

        if(prop === 'fontFamily'){

            let font = getTheFont(value);
            let stylesheet = '<link type="text/css" rel="stylesheet" href="https://fonts.googleapis.com/css2?family='+(font.family)+'&display=swap">';
            
            $('#d6-flex-iframe').contents().find('head').append(stylesheet)
            
        }




        /**
         * Set changes to state
         */
        let newChanges = {selector: selector, prop: prop, value: value, prevValue: prevValue};
        setChanges(newChanges)


        /**
         * 
         */


        let selectorSettings = settings[selector];

        selectorSettings[prop] = value;

        let newSettings = {
                ...settings,
                [selector] : selectorSettings
            };

        setSettings(newSettings);
        
        
        return newChanges;
    }



    const selectors = [

        {'name':'body', 'label': 'Body'},
        {'name':'h1', 'label': 'H1'},
        {'name':'h2', 'label': 'H2'},
        {'name':'h3', 'label': 'H3'},
        {'name':'h4', 'label': 'H4'},
        {'name':'h5', 'label': 'H5'},
        {'name':'h6', 'label': 'H6'},
        {'name':'button', 'label': 'Button'},
    ]


    const getFieldValue = (selector, prop) =>{

        let value = settings[selector][prop];

        /**
         * Show empty field if value is equal 'unset' or  'var(--something)'
         */

        return value.toLowerCase() === 'unset' || value.toLowerCase().indexOf('var') === 0 ? '' : value;
    }


    return (


        <div className={`style-menu crt-sub-menu ${subMenu === 2?'show':''}`} id="reostyle-menu">

            <div className="crt-inner-content">

                <div className="crt-sub-menu-title">Global Styles</div>

                <div className="crt-inner-content-wrap" style={{paddingTop:0}}>
                    
                

                    {
                        settings.body &&
                    
                        <>
                        
                            {
                                selectors.map((selector,i)=>{

                                    return <div className={`crt-field-collapse ${selector.name==='body'?'open':''}`} key={i}>
                                                <div className="crt-field-collapse-title">
                                                    {selector.label}
                                                    <span className="collapse-toggle"><img src={chevronDown} alt="" /></span>
                                                </div>
                                                <div className={`crt-field-collapse-content`} style={{display: selector.name==='body'?'block':'none'}}>
                                                    <div className="crt-field-group">



                                                        {
                                                            (selector.name === 'body' || selector.name === 'button')  &&
                                                            <div>

                                                                <div className="lbl">Primary Color</div>
                            
                                                                <div className="settings-color-picker">
                                                                    <div className="color-picker-field-wrap">
                                                                        <div className="color-picker-value"
                                                                            style={{backgroundColor: getFieldValue(selector.name,'primaryColor')}}>
                                                                        </div>
                            
                                                                        <input 
                                                                            type="text" 
                                                                            value={getFieldValue(selector.name,'primaryColor')} 
                                                                            placeholder="#FFFFFF"
                                                                            onBlur={()=>{saveChangesToDB(changes)}}
                                                                            onChange={ (event)=>{ 
                            
                                                                                handleSettingsChange(selector.name, 'primaryColor', event.target.value, settings[selector.name]['primaryColor'])
                            
                                                                            } } 
                                                                        />
                                                                    </div>
                                                                    
                                                                    <div className="color-picker-popover">
                            
                                                                        <span className="close-color-picker">
                                                                            <svg height="329pt" viewBox="0 0 329.26933 329" width="329pt" xmlns="http://www.w3.org/2000/svg"><path d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0"/></svg>
                                                                        </span>
                            
                                                                    
                                                                        <SketchPicker
                                                                            presetColors={presetColors}
                                                                            color={getFieldValue(selector.name,'primaryColor')} 
                                                                            disableAlpha={true}

                                                                            onChangeComplete={ (color, event)=>{ 
                                                                                let newChanges = handleSettingsChange(selector.name, 'primaryColor', color.hex, settings[selector.name]['primaryColor'])
                                                                                saveChangesToDB(newChanges); //because not onblur
                                                                            }}

                                                                            onChange={ (color, event)=>{ 
                            
                                                                                
                                                                          
                                                                            } } 
                                                                        />
                            
                                                                    </div>
                                                                </div>

                                                                <div style={{marginTop:'3px'}}></div>


                                                                <div className="lbl">Secondary Color</div>
                            
                                                                <div className="settings-color-picker">
                                                                    <div className="color-picker-field-wrap">
                                                                        <div className="color-picker-value"
                                                                            style={{backgroundColor: getFieldValue(selector.name,'secondaryColor')}}>
                                                                        </div>
                            
                                                                        <input 
                                                                            type="text" 
                                                                            value={getFieldValue(selector.name,'secondaryColor')} 
                                                                            placeholder="#FFFFFF"
                                                                            onBlur={()=>{saveChangesToDB(changes)}}
                                                                            onChange={ (event)=>{ 
                            
                                                                                handleSettingsChange(selector.name, 'secondaryColor', event.target.value, settings[selector.name]['secondaryColor'])
                            
                                                                            } } 
                                                                        />
                                                                    </div>
                                                                    
                                                                    <div className="color-picker-popover">
                            
                                                                        <span className="close-color-picker">
                                                                            <svg height="329pt" viewBox="0 0 329.26933 329" width="329pt" xmlns="http://www.w3.org/2000/svg"><path d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0"/></svg>
                                                                        </span>
                            
                                                                    
                                                                        <SketchPicker
                                                                            presetColors={presetColors}
                                                                            color={getFieldValue(selector.name,'secondaryColor')} 
                                                                            disableAlpha={true}
                                                                            
                                                                            onChangeComplete={ (color, event)=>{ 
                                                                                let newChanges = handleSettingsChange(selector.name, 'secondaryColor', color.hex, settings[selector.name]['secondaryColor'])
                                                                                saveChangesToDB(newChanges); //because not onblur
                                                                            }}

                                                                            onChange={ (color, event)=>{ 
                            
                            
                                                                            } } 
                                                                        />
                            
                                                                    </div>
                                                                </div>


                                                                {
                                                                    selector.name === 'body' &&
                                                                    <>
                                                                    
                                                                    <div style={{marginTop:'3px'}}></div>


                                                                    <div className="lbl">Tertiary Color</div>
                                
                                                                    <div className="settings-color-picker">
                                                                        <div className="color-picker-field-wrap">
                                                                            <div className="color-picker-value"
                                                                                style={{backgroundColor: getFieldValue(selector.name,'tertiaryColor')}}>
                                                                            </div>
                                
                                                                            <input 
                                                                                type="text" 
                                                                                value={getFieldValue(selector.name,'tertiaryColor')} 
                                                                                placeholder="#FFFFFF"
                                                                                onBlur={()=>{saveChangesToDB(changes)}}
                                                                                onChange={ (event)=>{ 
                                
                                                                                    handleSettingsChange(selector.name, 'tertiaryColor', event.target.value, settings[selector.name]['tertiaryColor'])
                                
                                                                                } } 
                                                                            />
                                                                        </div>
                                                                        
                                                                        <div className="color-picker-popover">
                                
                                                                            <span className="close-color-picker">
                                                                                <svg height="329pt" viewBox="0 0 329.26933 329" width="329pt" xmlns="http://www.w3.org/2000/svg"><path d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0"/></svg>
                                                                            </span>
                                
                                                                        
                                                                            <SketchPicker
                                                                                presetColors={presetColors}
                                                                                color={getFieldValue(selector.name,'tertiaryColor')} 
                                                                                disableAlpha={true}
                                                                                
                                                                                onChangeComplete={ (color, event)=>{ 
                                                                                    let newChanges = handleSettingsChange(selector.name, 'tertiaryColor', color.hex, settings[selector.name]['tertiaryColor'])
                                                                                    saveChangesToDB(newChanges); //because not onblur
                                                                                }}

                                                                                onChange={ (color, event)=>{ 
                                
                                
                                                                                } } 
                                                                            />
                                
                                                                        </div>
                                                                    </div>
                                                                    
                                                                    </>
                                                                }

                                                                <div style={{marginTop:'3px'}}></div>

                                                            </div>
                                                        }
                    
                                                        <div className="lbl">Font Family</div>

                                                        <Form.Select
                                                            fluid
                                                            options={fonts}
                                                            value={getFieldValue(selector.name,'fontFamily')}
                                                            onChange={
                                                                (e, { value })=>{
                                                                    let newChanges = handleSettingsChange(selector.name, 'fontFamily', value, settings[selector.name]['fontFamily'])
                                                                    saveChangesToDB(newChanges); //automatically save for select fields
                                                                }
                                                            }
                                                        />
                    
                                                        <div style={{marginTop:'3px'}}></div>
                                                        
                                                        <div className="lbl">Size</div>
                                                        <input 
                                                            type="text" 
                                                            value={getFieldValue(selector.name,'fontSize')} 
                                                            min={1}
                                                            max={120}
                                                            step={1}
                                                            onBlur={()=>{saveChangesToDB(changes)}}
                                                            onChange={ (event)=>{ 
                                                                handleSettingsChange(selector.name, 'fontSize', event.target.value, settings[selector.name]['fontSize'])
                                                            } } 
                                                        />
                    
                                                        <div style={{marginTop:'3px'}}></div>
                                                        
                                                        <div className="lbl">Line Height</div>
                                                        <input 
                                                            type="text" 
                                                            value={getFieldValue(selector.name,'lineHeight')} 
                                                            min={0}
                                                            max={5}
                                                            step={.1}
                                                            onBlur={()=>{saveChangesToDB(changes)}}
                                                            onChange={ (event)=>{ 
                                                                handleSettingsChange(selector.name, 'lineHeight', event.target.value, settings[selector.name]['lineHeight'])
                                                            } } 
                                                        />
                    
                                                        <div style={{marginTop:'3px'}}></div>
                                                        
                                                        <div className="lbl">Letter Spacing</div>
                                                        
                                                        <input 
                                                            type="text" 
                                                            value={getFieldValue(selector.name,'letterSpacing')} 
                                                            min={0}
                                                            max={20}
                                                            step={1}
                                                            onBlur={()=>{saveChangesToDB(changes)}}
                                                            onChange={ (event)=>{ 
                                                                handleSettingsChange(selector.name, 'letterSpacing', event.target.value, settings[selector.name]['letterSpacing'])
                                                            } } 
                                                        />
                    
                                                        <div style={{marginTop:'3px'}}></div>
                    
                                                        <div className="lbl">Font Weight</div>
                                                        <Form.Select
                                                            fluid
                                                            options={font_weights}
                                                            value={getFieldValue(selector.name,'fontWeight')} 
                                                            placeholder="Font Weight"
                                                            onChange={
                                                                (e, { value })=>{
                                                                    let newChanges = handleSettingsChange(selector.name, 'fontWeight', value, settings[selector.name]['fontWeight'])
                                                                    saveChangesToDB(newChanges); //automatically save for select fields
                                                                }
                                                            }
                                                        />
                                                        
                    
                                                        <div style={{marginTop:'3px'}}></div>
                    
                                                        <div className="lbl">Text Transform</div>
                                                        <Form.Select
                                                            fluid
                                                            options={text_transform}
                                                            value={getFieldValue(selector.name,'textTransform')} 
                                                            placeholder="Text Transform"
                                                            onChange={
                                                                (e, { value })=>{
                                                                    let newChanges = handleSettingsChange(selector.name, 'textTransform', value, settings[selector.name]['textTransform'])
                                                                    saveChangesToDB(newChanges); //automatically save for select fields
                                                                }
                                                            }
                                                        />
                    
                    
                                                        <div style={{marginTop:'3px'}}></div>
                    
                                                        <div className="lbl">Color</div>
                    
                                                        <div className="settings-color-picker">
                                                            <div className="color-picker-field-wrap">
                                                                <div className="color-picker-value"
                                                                    style={{backgroundColor: getFieldValue(selector.name,'color')}}>
                                                                </div>
                    
                                                                <input 
                                                                    type="text" 
                                                                    value={getFieldValue(selector.name,'color')} 
                                                                    placeholder="#FFFFFF"
                                                                    onBlur={()=>{saveChangesToDB(changes)}}
                                                                    onChange={ (event)=>{ 
                    
                                                                        handleSettingsChange(selector.name, 'color', event.target.value, settings[selector.name]['color'])
                    
                                                                    } } 
                                                                />
                                                            </div>
                                                            
                                                            <div className="color-picker-popover">
                    
                                                                <span className="close-color-picker">
                                                                    <svg height="329pt" viewBox="0 0 329.26933 329" width="329pt" xmlns="http://www.w3.org/2000/svg"><path d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0"/></svg>
                                                                </span>
                    
                                                               
                                                                <SketchPicker
                                                                    presetColors={presetColors}
                                                                    color={getFieldValue(selector.name,'color')} 
                                                                    disableAlpha={true}

                                                                    onChangeComplete={ (color, event)=>{ 

                                                                        let newChanges = handleSettingsChange(selector.name, 'color', color.hex, settings[selector.name]['color'])
                                                                        saveChangesToDB(newChanges); //because not onblur
                                                                    }}

                                                                    onChange={ (color, event)=>{ 
                    
                                                                        
                                                                        
                                                                    } } 
                                                                />
                    
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                })
                            }
                        </>
                    }

                    
                </div>
            </div>
            <div className="crt-menu-overlay" onClick={()=>hideSubMenu()}></div>
        </div>


    );
}


export default SubMenuSettings;