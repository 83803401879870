import React from "react";
import logo from '../../assets/img/logo-icon.png';
import plus from '../../assets/img/plus.svg';
import brush from '../../assets/img/brush.svg';
import reorder from '../../assets/img/reorder.svg';
import desktop from '../../assets/img/desktop.svg';
import tablet from '../../assets/img/tablet.svg';
import mobile from '../../assets/img/mobile.svg';
import tablink from '../../assets/img/tab-link.svg';
import html from '../../assets/img/html.svg';
import wordpress from '../../assets/img/wordpress.svg';
import pagesIcon from '../../assets/img/pages2.svg';
//import chevronDown from '../../assets/img/chevron-down.svg';
import download from '../../assets/img/download.svg';

import SubMenuBlocks from "./SubMenuBlocks";
import SubMenuReorderBlocks from "./SubMenuReorderBlocks";
import SubMenuSettings from "./SubMenuSettings";
import SubMenuPages from "./SubMenuPages";

import {useState, useEffect} from "react"

import $ from 'jquery';

function Sidebar({pages, setPages, addBlock, flexPagePermalink, flexPageId, addBlockButtonRef, pageBlocks, reorderBlocks, previewClass, setPreviewClass, settings, setSettings, fonts, projectId}) {

    
    
    const [subMenu, showSubMenu] = useState(0);
    
    let preview_img = desktop;

    if (previewClass === 'tablet'){
        preview_img = tablet
    } else  if (previewClass === 'mobile'){
        preview_img = mobile
    }


    const toggleCrtSub = function(e){

        e.preventDefault();

        $('.crt-item').not($(e.currentTarget).closest('.crt-item')).removeClass('show-sub');

        $(e.currentTarget).closest('.crt-item').toggleClass('show-sub');
    }

    const hideCrtSub = function(e){

        $('.crt-item').not($(e.currentTarget).closest('.crt-item')).removeClass('show-sub');

        $(e.currentTarget).closest('.crt-item').toggleClass('show-sub');
    }


    const hideSubMenu = function(e){
        showSubMenu(0)
    }


    useEffect( () => { 


       
    }, []);


    return (
        <div className="side-menu">


                <div className="side-menu-content">


                    <div className="app-logo">
                        {/*<a href="/">*/}
                            <img src={logo} alt=""/>
                        {/*</a>*/}
                    </div>

                    <div className="crt-items">
                        <div className="crt-item">
                            <a href="#add" className={`crt-add-blocks ${subMenu === 1?'active':''}`} onClick={()=>showSubMenu(subMenu === 1 ? 0 : 1)} ref={addBlockButtonRef}><img src={plus} alt=""/></a>
                        </div>

                        <div className="crt-item">
                            <a href="#layer" className={`crt-style-blocks ${subMenu === 3?'active':''}`} onClick={()=>showSubMenu(subMenu === 3 ? 0 : 3)}><img src={reorder} alt=""/></a>
                        </div>

                        <div className="crt-item">
                            <a href="#style" className={`crt-style-blocks ${subMenu === 2?'active':''}`} onClick={()=>showSubMenu(subMenu === 2 ? 0 :2)}><img src={brush} alt=""/></a>
                        </div>

                        <div className="crt-item">
                            <a href="#style" className={`crt-pages-blocks ${subMenu === 4?'active':''}`} onClick={()=>showSubMenu(subMenu === 4 ? 0 :4)}><img src={pagesIcon} alt=""/></a>
                        </div>
                        
                    </div>

                    <div className="crt-items bottom">
                        <div className="crt-item">
                            <a href="#preview" onClick={(e)=>toggleCrtSub(e)}>

                                <img src={preview_img} alt=""/>
                            </a>
                            <div className="crt-item-sub">
                                <ul className="crt-preview-sub">
                                    <li><a href="#desktop" onClick={(e)=>{e.preventDefault(); setPreviewClass('desktop'); toggleCrtSub(e)}}><img src={desktop} alt=""/></a></li>
                                    <li><a href="#tablet" onClick={(e)=>{e.preventDefault(); setPreviewClass('tablet'); toggleCrtSub(e)}}><img src={tablet} alt=""/></a></li>
                                    <li><a href="#mobile" onClick={(e)=>{e.preventDefault(); setPreviewClass('mobile'); toggleCrtSub(e)}}><img src={mobile} alt=""/></a></li>
                                    <li><a target="_blank" rel="noopener noreferrer" href={`${flexPagePermalink}`} onClick={(e)=>{hideCrtSub(e)}}><img src={tablink} alt=""/></a></li>
                                </ul>
                            </div>

                        </div>
                        <div className="crt-item" style={{display:'none'}}>
                            <a href="#download" onClick={(e)=>toggleCrtSub(e)}><img src={download} alt=""/></a>

                            <div className="crt-item-sub">
                                <ul className="crt-preview-sub">
                                    <li><a target="_blank" rel="noopener noreferrer" href={process.env.REACT_APP_URL+'/?action=d6-flex-export&type=html&id='+flexPageId}><img src={html} alt=""/></a></li>
                                    <li><a target="_blank" rel="noopener noreferrer" href={process.env.REACT_APP_URL+'/?action=d6-flex-export&type=wordpress&id='+flexPageId}><img src={wordpress} alt=""/></a></li>
                                </ul>
                            </div>

                        </div>
                    </div>

                </div>

                <SubMenuBlocks subMenu={subMenu} hideSubMenu={hideSubMenu} addBlock={addBlock}/>
                <SubMenuPages pages={pages} setPages={setPages} subMenu={subMenu} hideSubMenu={hideSubMenu} settings={settings} setSettings={setSettings} flexPageId={flexPageId} fonts={fonts} projectId={projectId}/>
                
                <SubMenuReorderBlocks subMenu={subMenu} hideSubMenu={hideSubMenu} pageBlocks={pageBlocks} reorderBlocks={reorderBlocks}/>
                <SubMenuSettings subMenu={subMenu} hideSubMenu={hideSubMenu} settings={settings} setSettings={setSettings} flexPageId={flexPageId} fonts={fonts}/>

               
        </div>

    );
}


export default Sidebar;