
import React, { useEffect, useState,useRef } from "react";
import $ from 'jquery';
import axios from 'axios';
import Dropzone from 'react-dropzone'

//import { RawHTML } from '@wordpress/element';
//import { serializeRawBlock, getBlockContent, createBlock, serialize, cloneBlock, __unstableSerializeAndClean, pasteHandler, rawHandler, parse, renderBlock } from '@wordpress/blocks';
//import { parse as xxxx } from '@wordpress/block-serialization-default-parser';
//import { registerCoreBlocks } from '@wordpress/block-library';
//registerCoreBlocks(); //we need core blocks to parse our raw gutenberg blocks

function BlockEditor({sectionToEdit, setSectionToEdit, pageBlocks, setPageBlocks, flexPageId, updateBlock}) {

    const generateContentInputRef = useRef(null);
    const editableBlocks = ['core/cover', 'core/paragraph', 'core/heading', 'core/button', 'core/image', 'core/list', 'core/list-item']
    const [newPageBlocks, setNewPageBlocks] = useState([]);
    const [fields, setFields] = useState([]);
    // eslint-disable-next-line
    const [random, setRandom] = useState(0);
    const [uploadingImgKey, setUploadingImgKey] = useState(-1);
    const [hasChange, setHasChange] = useState(false);
    const [saveUploadedFile, setSaveUploadedFile] = useState(false);
    const [showGenerateContent, setShowGenerateContent] = useState(false);
    const [isGenerating, setIsGenerating] = useState(false);
    const [saveGeneratedContent, setSaveGeneratedContent] = useState(0);
    let newFields = [];



    const savePage = () =>{
    

        if(hasChange){


            setHasChange(false)

            axios.post(process.env.REACT_APP_API_ENDPOINT + '/flex-update-block/',{section_index: sectionToEdit, flex_page_id: flexPageId, section: pageBlocks[sectionToEdit].block_json})

                .then(res => {

                    updateBlock({block_html: res.data.html, css: res.data.css}, sectionToEdit)
                })

                .catch((error) => {

                });

        }
    }

    


    const getEditableInnerBlocks = (block) =>{


        if(typeof block === 'object' && block.length > 0){


            block.forEach(innerBlock => {

                newFields.push(innerBlock)

                getEditableInnerBlocks(innerBlock.innerBlocks)

            });
    
        }


    }

  


    const handleChange = (block, value, attr) =>{
        

        let $blockInnerHTML         = $(block.innerHTML);
        let innerContent            = block.innerContent.map(item=>item).join('||');
        let $blockInnerContent      = $(innerContent);


        if(block.blockName === 'core/heading' || block.blockName === 'core/paragraph' || block.blockName === 'core/list-item'){
            $blockInnerHTML.text(value)
            $blockInnerContent.text(value)
        }
        else if( block.blockName === 'core/button'){

            if(attr === 'text'){
                $blockInnerHTML.find('a').text(value)
                $blockInnerContent.find('a').text(value)
            }else if(attr === 'href'){
                $blockInnerHTML.find('a').attr('href',value)
                $blockInnerContent.find('a').attr('href',value)
            }
            
        }
        else if(block.blockName === 'core/image'){
            
            $blockInnerHTML.find('img').attr('src', value).attr('alt', '')
            $blockInnerContent.find('img').attr('src', value).attr('alt', '')
        }
        else if(block.blockName === 'core/cover'){
            
            $blockInnerHTML.find('>img').attr('src', value).attr('alt', '')
            $blockInnerContent.find('>img').attr('src', value).attr('alt', '')

            block.attrs.url = value;
            //block.attrs.id = 0;
        }


        let blockInnerContentArr = $blockInnerContent.prop('outerHTML').split('||').map(item=>{return (item==='')?null:item})



        block.innerHTML = $blockInnerHTML.prop('outerHTML');
        block.innerContent = blockInnerContentArr;
        block.attrs.isEdited = true; //we need this so paragraph field won't disappear if value is empty


        setFields(fields);
        setPageBlocks(newPageBlocks)
        setRandom(Math.random())
        setHasChange(true)

    }
    

    const handleDropFiles = (acceptedFiles, block, key) => {


        setUploadingImgKey(key);
        

        let formData = new FormData();

        acceptedFiles.map((file) => {
            return formData.append('Files[]', file);
        });

        //formData.append('title', this.state.title);

        let headers = { headers: {'Content-Type': 'multipart/form-data'} };

        axios.post(process.env.REACT_APP_API_ENDPOINT + '/upload-block-image', formData , headers)
            .then(res => {
               
                if (res.data.success){


                    handleChange(block, res.data.images[0].url, 'src');
                    setHasChange(true);
                    setSaveUploadedFile(true);

                    


                    // if(field.type === 'image'){

                    //     field.el.attr('src',res.data.images[0].url); 

                    // }


                    // let fields = this.state.element.fields;
                    // fields[x].value = res.data.images[0].url;

                    // this.setState({
                    //     element: {...this.state.element, fields: fields}
                    // });

                    //this.updateBlock( this.state.element, field, res.data.images[0].url);


                } else {

                    alert(res.data.msg)

                }

                setUploadingImgKey(-1);

            })


            .catch((error) => {

                setUploadingImgKey(-1);

                alert(error.response)

            });

    }


    const generateContent = async () =>{

        let sectionDescription = $('#section-description-field').val();


        if(sectionDescription.trim() === ''){
            alert('Please describe what this section about')
        }

        setIsGenerating(true)

        let contentFields = '', contentFieldsArr = [], hC = 0, pC = 0, count = 0;
        
        
        fields.forEach((field, v)=>{
            


            if(field.blockName==='core/heading' || field.blockName==='core/paragraph'){

                let $p = $(field.innerHTML);

                if(field.blockName==='core/paragraph'){


                    if( !$p.text() && !field.attrs.isEdited){
                        
                    }else{

                        count++;
                        pC++;
                        contentFields += `${(count)}. Short Copy ${pC}\n`;
                        contentFieldsArr.push(field);
                        
                    }

                }else if(field.blockName==='core/heading'){

                    hC++;
                    count++;
                    contentFields += `${(count)}. Heading ${(hC)}\n`;
                    contentFieldsArr.push(field)
                    
                }
                
            }

        })
        
        
        //console.log(contentFields)
        //console.log(contentFieldsArr)


        let prompt = `write a value for the following items for web page section, the topic is ${sectionDescription}, please return in json format:\n${contentFields}`;

        try{


            const apiKey = process.env.REACT_APP_CHATGPT_API

            const response = await axios.post(
                "https://api.openai.com/v1/chat/completions",
                {
                messages: [{"role": "user", "content": prompt}],
                model: 'gpt-3.5-turbo',
                //max_tokens: 1000,
                //n: 1,
                //stop: ".",
                },
                {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${apiKey}`,
                },
                }
            );


            if(response.status === 200){


                //console.log(response.data.choices[0].message.content)

                let messageJSON = JSON.parse(response.data.choices[0].message.content.replace(/(\r\n|\n|\r)/gm, ""));

                let i = 0;
                for (var key of Object.keys(messageJSON)) {
                    handleChange(contentFieldsArr[i],messageJSON[key],'text');
                    i++;
                }

                
            } else {

                throw response.statusText;
                
            }

            setSaveGeneratedContent(Math.random())
            
            
        } catch (err){

            alert( err.message )

        }

        
        setIsGenerating(false)
        
    }


    useEffect(() => {

        setNewPageBlocks(JSON.parse(JSON.stringify(pageBlocks)))

    },[pageBlocks])



    useEffect(() => {

        if(generateContentInputRef.current !== null){
            generateContentInputRef.current.focus()
        }

    },[generateContentInputRef, showGenerateContent])



    useEffect(() => {

        savePage()
        setSaveUploadedFile(false)

    // eslint-disable-next-line
    },[saveUploadedFile])


    useEffect(() => {

        savePage()
        setShowGenerateContent()

    // eslint-disable-next-line
    },[saveGeneratedContent])


    useEffect(() => {

        if(sectionToEdit >= 0){
   
            getEditableInnerBlocks(newPageBlocks[sectionToEdit]?.block_json.innerBlocks)
            setFields(newFields);

        }

    // eslint-disable-next-line
    }, [sectionToEdit, newPageBlocks])



    let headingCount = 0, buttonCount = 0, coverCount = 0, imageCount = 0, copyCount = 0, listCount = 0;



    if(fields.length <= 0){

        return;
    }
    

    return (
        <div className={`d6f-block-editor ${(sectionToEdit > -1 )?'show':''}`}>
            <span className="close-editor-panel" onClick={()=>{setSectionToEdit(-1)}}>
                <svg height="329pt" viewBox="0 0 329.26933 329" width="329pt" xmlns="http://www.w3.org/2000/svg"><path d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0"/></svg>
            </span>
            
            <div className="d6f-editor-panel">
                <div className="d6f-block-editor-title">Edit Block</div>

                <div className={`d6f-editor-panel-content ${isGenerating?' generating-content':''}`}>

                    <div className="generate-content-wrap">
                        {   !showGenerateContent &&
                            <div className="element-field-group">
                                <button className="generate-content-btn" onClick={()=>{setShowGenerateContent(true)}}>Generate Content</button>     
                            </div>
                        }

                        { showGenerateContent && 

                            <div className="element-field-group">
                                <div>
                                    <textarea ref={generateContentInputRef} id="section-description-field" placeholder={`Describe what this section is about. \n\nEx: Commercial Tree care services.`}></textarea>

                                    {
                                        isGenerating ?
                                        <button className="generate-content-btn">Generating...</button>     
                                        :
                                        <button className="generate-content-btn" onClick={()=>{generateContent()}}>Generate</button>     
                                    }
                                    
                                </div>
                            </div>
                        }

                    </div>


                    <div className={`block-fields`}>
                        {fields.map((block, key)=>{

                            if(editableBlocks.includes(block.blockName)){

                                if(block.blockName === 'core/heading'){

                                    return <div className="element-field-group" key={key}>
                                                <div className="element-label">
            
                                                    {
                                                        (function(){
                                                            headingCount++;
                                                            return 'Heading ' + headingCount;
                                                        })()
                                                    }
                                                </div>
                                                <input 
                                                    type="text" 
                                                    value={$(block.innerHTML).text()} 
                                                    onBlur={()=>savePage()}
                                                    onChange={ (event)=>{ 
                                                        handleChange(block, event.target.value, 'text')
                                                    } } 
                                                />
                                            </div> 
                                } 
                                
                                
                                else if(block.blockName === 'core/list'){

                                    return <div className="element-field-group" key={key}>
                                                <div className="element-label">
            
                                                    {
                                                        (function(){
                                                            listCount++;
                                                            return 'List ' + listCount;
                                                        })()
                                                    }
                                                </div>
                                            </div> 
                                }


                                else if(block.blockName === 'core/list-item'){

                                    return <div className="element-field-group" key={key}>

                                                <input 
                                                    type="text" 
                                                    value={$(block.innerHTML).text()} 
                                                    onBlur={()=>savePage()}
                                                    onChange={ (event)=>{ 
                                                        handleChange(block, event.target.value, 'text')
                                                    } } 
                                                />
                                            </div> 
                                }

                                
                                else if(block.blockName ==='core/button'){

                                    return <div className="element-field-group" key={key}>
                                                <div className="element-label">
            
                                                    {
                                                        (function(){
                                                            buttonCount++;
                                                            return 'Button '+ buttonCount;
                                                        })()
                                                    }
                                                </div>
                                                <input 
                                                    type="text" 
                                                    value={$(block.innerHTML).find('a').text()} 
                                                    onBlur={()=>savePage()}
                                                    onChange={ (event)=>{ 
                                                        handleChange(block, event.target.value, 'text')
                                                    } } 
                                                />
                                                <input 
                                                    style={{marginTop:'5px'}}
                                                    type="text" 
                                                    placeholder="https://"
                                                    value={$(block.innerHTML).find('a').attr('href')} 
                                                    onBlur={()=>savePage()}
                                                    onChange={ (event)=>{ 
                                                        handleChange(block, event.target.value, 'href')
                                                    } } 
                                                />
                                            </div> 
                                }
                                
                                
                                else if(block.blockName === 'core/paragraph'){


                                    let $p = $(block.innerHTML);

                                    if( !$p.text() && !block.attrs.isEdited){
                                        return <div key={key}></div>
                                    }

        
                                    return <div className="element-field-group" key={key}>
                                                <div className="element-label">
                                                    {
                                                        (function(){
                                                            copyCount++;
                                                            return 'Copy '+ copyCount;
                                                        })()
                                                    }
                                                </div>
                                                <textarea 
                                                    value={$(block.innerHTML).text()} 
                                                    onBlur={()=>savePage()}
                                                    onChange={ (event)=>{ 
                                                        handleChange(block, event.target.value, 'text')
                                                    } } 
                                                />
                                            </div>
        
                                }
                                
                                else if(block.blockName === 'core/cover'){
        
                                    return <div className="element-field-group" key={key}>
                                                <div className="element-label">
                                                    {
                                                        (function(){
                                                            coverCount++;
                                                            return 'Cover '+ coverCount;
                                                        })()
                                                    }
            
                                                </div>
            
                                                <div className="element-img">
                                                    <img src={$(block.innerHTML).find('img').attr('src')} alt="" />
                                                </div>

                                                <Dropzone 
                                                        
                                                        accept={
                                                            {
                                                                'image/jpeg': ['.jpeg'],
                                                                'image/jpg': ['.jpg'],
                                                                'image/png': ['.png'],
                                                                'image/gif': ['.gif'],
                                                                //'image/svg+xml': ['.svg'],
                                                            }
                                                        }

                                                        multiple={false}
                                                        onDrop={(acceptedFiles)=>handleDropFiles(acceptedFiles, block, key)}
                                                        //onDrop={acceptedFiles => console.log(acceptedFiles)}
                                                    >
                                                        {({getRootProps, getInputProps}) => (
                                                            <section>
                                                            <div {...getRootProps()}>
                                                                <input {...getInputProps()} />
                                                                <div className="element-upload-img">
                                                                    {
                                                                        (()=>{

                                                                            if(key === uploadingImgKey)
                                                                            return 'Uploading...';

                                                                            return $(block.innerHTML).find('img').attr('src') !== '' 
                                                                                ? (`Replace Image`)
                                                                                : (`Upload Image`)
                                                                            
                                                                        })()
                                                                    }
                                                                </div>
                                                            </div>
                                                            </section>
                                                        )}
                                                </Dropzone>

                                                
                                            </div>
        
                                }
                                
                                else if(block.blockName === 'core/image'){
        
                                    return <div className="element-field-group" key={key}>
                                                <div className="element-label">
                                                    {
                                                        (function(){
                                                            imageCount++;
                                                            return 'Image '+ imageCount;
                                                        })()
                                                    }
            
                                                </div>
            
                                                <div className="element-img">
                                                    <img src={$(block.innerHTML).find('img').attr('src')} alt="" />
                                                </div>


                                                <Dropzone 
                                                        
                                                        accept={
                                                            {
                                                                'image/jpeg': ['.jpeg'],
                                                                'image/jpg': ['.jpg'],
                                                                'image/png': ['.png'],
                                                                'image/gif': ['.gif'],
                                                                //'image/svg+xml': ['.svg'],
                                                            }
                                                        }

                                                        multiple={false}
                                                        onDrop={(acceptedFiles)=>handleDropFiles(acceptedFiles, block, key)}
                                                        //onDrop={acceptedFiles => console.log(acceptedFiles)}
                                                    >
                                                        {({getRootProps, getInputProps}) => (
                                                            <section>
                                                            <div {...getRootProps()}>
                                                                <input {...getInputProps()} />
                                                                <div className="element-upload-img">
                                                                    {
                                                                        (()=>{

                                                                            if(key === uploadingImgKey)
                                                                            return 'Uploading...';

                                                                            return $(block.innerHTML).find('img').attr('src') !== '' 
                                                                                ? (`Replace Image`)
                                                                                : (`Upload Image`)
                                                                            
                                                                        })()
                                                                    }
                                                                </div>
                                                            </div>
                                                            </section>
                                                        )}
                                                </Dropzone>
                                            </div>
        
                                } 
                                
                                
                                else{

                                    return <div key={key}></div>
                                }


                            }else{

                                return <div key={key}></div>
                            }
                            
                        })}
                    </div>


                </div>
            </div>
            
        </div>

    );
}


export default BlockEditor;