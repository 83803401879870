import React from "react";

import axios from 'axios';
import {useState, useEffect} from "react"


function SubMenuBlocks({subMenu, hideSubMenu, addBlock}) {


    const [blocksFetched, setBlocksFetched] = useState(false);
    const [blocks, setBlocks] = useState([]);
    const [blocksSubMenu, setBlocksSubMenu] = useState(-1);
    

    const getBlocks = function() {

        axios.get(process.env.REACT_APP_API_ENDPOINT + '/flex-blocks/')

            .then(res => {

                let blocks = res.data.blocks === undefined ? [] : res.data.blocks;

                setBlocksFetched(true);
                setBlocks(blocks);

            })

            .catch((error) => {

                setBlocksFetched(true);

            });

    }


    useEffect( () => { 

        getBlocks();

    }, []);



    return (
        <div className={`blocks-menu crt-sub-menu ${subMenu === 1?'show':''}`} id="blocks-menu">

            <div className="crt-inner-content">

                <div className="crt-sub-menu-title">Blocks</div>

                <div className="crt-inner-content-wrap">
                    
                    <div>

                        {
                            ( blocksFetched ) 
                            
                            ? 
                        
                            <ul className="menu-items">

                                {
                                    blocks.map((group, i) => (

                                        <li className={`${blocksSubMenu === i ?'active':''}`} key={i} onClick={(e)=>setBlocksSubMenu(i)}>
                                            <span>{group.title}</span>
                                            <div className="blocks-sub-menu" onClick={()=>hideSubMenu()}>
                                                <ul>

                                                    {
                                                        group.blocks.map((block, x) => (
                                                            <li key={x} onClick={()=>{addBlock(block); hideSubMenu()}}>
                                                                <img src={block.img} alt="" />
                                                            </li>
                                                        ))
                                                    }

                                                </ul>
                                            </div>
                                        </li>

                                    ))
                                }

                            </ul>

                            :

                            <div style={{fontSize:'12px'}}> Loading Blocks...</div>

                        }
                    </div>
                </div>
            </div>

            <div className="crt-menu-overlay" onClick={()=>hideSubMenu()}></div>

        </div>

    );
}


export default SubMenuBlocks;