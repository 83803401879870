import {useEffect} from "react";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';


//import { withRouter } from "react-router-dom";



function Start() {

    const navigate = useNavigate();

    useEffect(() => {


        axios.get(process.env.REACT_APP_API_ENDPOINT + '/flex-new-page/')

        .then(res => {

            navigate('/projects/' + res.data.project_id + '/' + res.data.page_id + '/');

        })

        .catch((error) => {


        });


      return () => {
        
      };


    }, [navigate])

    return (
        <div className="page-wrap">

            <div className="text-center" style={{padding: '50px 0'}}>
                <h2>Start</h2>
            </div>

        </div>
    );
  }
  
export default Start;