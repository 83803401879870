import React from "react";
//import { withRouter } from "react-router-dom";

import "../assets/css/404.scss";


function Page404() {
    return (
        <div className="page-wrap">

            <div>
                404
            </div>

        </div>
    );
  }
  
export default Page404;