import React from "react";
import Sidebar from '../components/partials/Sidebar';
import Footer from '../components/partials/Footer';
import BlockEditor from '../components/partials/BlockEditor';
import plus from '../assets/img/plus-black.svg';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {useState, useEffect, useRef} from "react"
import $ from 'jquery';

//import {styleObjToString} from './utils/helpers';
import Iframe from "./Iframe";
//import parse from 'html-react-parser';


window.$j = $;


let pageBlocksForNonReactJs = []; //we need this because the "pageBlocks" state is not accessible via native JS


function Page() {

    const navigate = useNavigate();
    const addBlockButtonRef = useRef(null);
    


    
    const [previewClass, setPreviewClass] = useState('desktop');
    const [settings, setSettings] = useState({});
    const [fonts, setFonts] = useState([]);
    const [pages, setPages] = useState([]);

    
    const [insertNewBlockAfterBlock, setInsertNewBlockAfterBlock] = useState(-1);
    const [isLoading, setIsLoading] = useState(true);
    
    const [isSaving, setIsSaving] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [pageBlocks, setPageBlocks] = useState([]);
    const [flexPagePermalink, setFlexPagePermalink] = useState('');
    const [pageBlankTemplate, setPageBlankTemplate] = useState('');
    const [sectionToEdit, setSectionToEdit] = useState(-1);

    const [forceIframeRender, setForceIframeRender] = useState(0);

    const {flexPageId} = useParams();
    const {projectId} = useParams();


    const blockControl = '<div class="d6f-block-control">' +
        '<span class="d6f-block-item-control d6f-remove-block"></span>'+
        '<span class="d6f-block-item-control d6f-move-up-block"></span>'+
        '<span class="d6f-block-item-control d6f-move-down-block"></span>'+
        '<span class="d6f-block-item-control d6f-edit-block"></span>'+
        '<span class="d6f-block-item-control d6f-insert-block"></span>'+
    '</div>';


    

    const makeBlockId = (length) => {

        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }



    const reorderBlocks = (page_blocks, fromIndex, toIndex) =>{


        setPageBlocks(page_blocks)

        if (fromIndex !== toIndex){


            let $container = getBlocksWrapper();


            /**
             * Reorder the blocks in the iFrame
             */

            if (fromIndex < toIndex){

                //drag direction down
                $container.find('.blder-block-item').eq(fromIndex).insertAfter( $container.find('.blder-block-item').eq(toIndex) );

            }else{

                //drag direction up
                $container.find('.blder-block-item').eq(fromIndex).insertBefore( $container.find('.blder-block-item').eq(toIndex) );

            }


            savePage({page_blocks: page_blocks});

        }

    }

    const setIframeHeight = () =>{

        // setTimeout(function(){
        //     $('#d6-flex-iframe').css({ height: $('#d6-flex-iframe').contents().find('body').height() });
        // },1)


        setInterval(function(){
            $('#d6-flex-iframe').css({ height: $('#d6-flex-iframe').contents().find('body').height() });
        },1000)
    }
    

    const scrollToBlock = (newBlockKey) =>{

        
        setTimeout(function () {

          
        },20);

    }


    const preloadImages = (imageUrls, callback) => {
        var loadedImages = 0;
        var totalImages = imageUrls.length;
      
        function imageLoaded() {
          loadedImages++;
          if (loadedImages === totalImages) {
            callback();
          }
        }
      
        for (var i = 0; i < totalImages; i++) {
          var img = new Image();
          img.onload = imageLoaded;
          img.onerror = imageLoaded; // Continue even if image fails to load
          img.src = imageUrls[i];
        }
    }

    const onImagesPreloaded = () => {
        //setIframeHeight();
        setIsLoading(false);
        setIsSaving(false);
        setIsUpdating(false);
        scrollToBlock();
    }


    const iframeImagesLoaded = (block) => {

        //let numImages = $(block).find('img').length;
        //var loaded = 0;

        let imageUrls = [];

        $(block).find('img').each(()=>{
            imageUrls.push($(this).attr('href'))
        })

        preloadImages(imageUrls, onImagesPreloaded)



        //fallback
        setTimeout(()=>{

            setIsLoading(false)
            setIsSaving(false)
            setIsUpdating(false)
            //setIframeHeight()

        },5000)
        
    }




    const addBlockControls = () => {


        (function(blockControl){

            $('#d6-flex-iframe').contents().find('.blder-block-item').each(function () {

                if ( !$(this).find('.d6f-block-control').length ){
                    $(this).append(blockControl);
                }
    
            })

        })(blockControl)
        

    }



    const savePage = (params) => {

        setIsSaving(true);



        params = Object.assign({
            page_blocks         : pageBlocks, 
            flex_page_id        : flexPageId,
        }, params);


        params.page_blocks = params.page_blocks.map(block => block.block_json);

        axios.post(process.env.REACT_APP_API_ENDPOINT + '/flex-save/', params)
        
        .then(res => {

            setIsSaving(false)

        })
        
        .catch((error) => {

        });

    }



    const removeBlock = (el) => {

        let $block = el.closest('.blder-block-item');
        let block_key = $block.index();
        

        if (block_key !== '') {


            let page_blocks = JSON.parse(JSON.stringify(pageBlocksForNonReactJs));
            page_blocks.splice(block_key, 1);

            $block.remove();
            setPageBlocks(page_blocks);
            setInsertNewBlockAfterBlock(-1);
            savePage({page_blocks: page_blocks});

        }

    }


    const moveUpDownBlock = (prop1, prop2) => {

        

        if(pageBlocksForNonReactJs && pageBlocksForNonReactJs[prop1] && pageBlocksForNonReactJs[prop2]){

            let obj = JSON.parse(JSON.stringify(pageBlocksForNonReactJs));

            let tmp = obj[prop1];
            obj[prop1] = obj[prop2];
            obj[prop2] = tmp;

            setPageBlocks(obj)
            savePage({page_blocks: obj});

        }
    }


    const moveBlockUp = (el) =>{

        

        let $block = el.closest('.blder-block-item');
        
        let blockIdx = parseInt($block.index());

 

        /***
         * Move the Block Up
         */

        $block.slideUp('fast');


        (function ($block) {


            setTimeout(function () {
                $block.prev().insertAfter($block);
            },300);

            setTimeout(function () {
                $block.slideDown('fast');
            },600);



            setTimeout(function () {

                /**
                 * Update Blocks object
                 */

                if (blockIdx > 0) {
                    
                    moveUpDownBlock( blockIdx, blockIdx - 1);
                }


                /**
                 * Save
                 */

                setTimeout(function () {
                    //savePage({});
                },100);

            },900);





        })($block);

    }


    const moveBlockDown = (el) => {


        let $block = el.closest('.blder-block-item');
        
        let blockIdx = parseInt($block.index());


        /***
         * Move the Block Down
         */

        $block.slideUp('fast');

        (function ($block) {
            setTimeout(function () {
                $block.next().insertBefore($block);
            },300);

            setTimeout(function () {
                $block.slideDown('fast');
            },600);


            setTimeout(function () {

                /**
                 * Update Blocks object
                 */

                if (blockIdx < pageBlocksForNonReactJs.length - 1) {
                    moveUpDownBlock(blockIdx, blockIdx + 1);
                }


                /**
                 * Save
                 */

                setTimeout(function () {
                    //savePage({});
                },100);

            },900);



        })($block);


    }

    const blockActions = () =>{

        let $iframeBody = $('#d6-flex-iframe').contents().find('body');


        /**
         * Block Click
         */
        $iframeBody.off('click.editBlock');
        $iframeBody.on('click.editBlock', '.d6f-block-control span.d6f-edit-block', function () {

            setSectionToEdit($(this).closest('.blder-block-item').index());

        });
   


        /**
         * Remove Block
         */
     
        $($iframeBody).off('click.deleteBlock');

        $iframeBody.on('click.deleteBlock', '.d6f-block-control span.d6f-remove-block', function (e) {

            e.stopPropagation();

            removeBlock($(this));

        });



        /**
         * Move Block Up
         */

        $($iframeBody).off('click.moveUpBlock');

        $iframeBody.on('click.moveUpBlock', '.d6f-block-control span.d6f-move-up-block', function (e) {

            e.stopPropagation();

            let el = $(this);
            console.log('pageBlocks', pageBlocks)

            moveBlockUp(el);

        });

        /**
         * Move Block Down
         */

        $($iframeBody).off('click.moveDownBlock');

        $iframeBody.on('click.moveDownBlock', '.d6f-block-control span.d6f-move-down-block', function (e) {

            e.stopPropagation();

            let el = $(this);

            moveBlockDown(el);

        });


      


        /**
         * Insert Block
         */

        $($iframeBody).off('click.insertBlock');

        $iframeBody.on('click.insertBlock', '.d6f-block-control span.d6f-insert-block', function (e) {

    
            e.stopPropagation();
           
            setInsertNewBlockAfterBlock(parseInt($(this).closest('.blder-block-item').index()))

            addBlockButtonRef.current.click();
            //that.moveBlockDown(el);
        });


    }



    const getBlocksWrapper = () =>{


        let $container = $('#d6-flex-iframe').contents().find('#page-wrapper .wp-block-post-content');

        if(!$container.length){
            $container = $('#d6-flex-iframe').contents().find('#page-wrapper');
        }

        return $container;

    }


    const afterBlocksAdded = () =>{

        addBlockControls();
        blockActions();

        setTimeout(function(){
            $('#d6-flex-iframe').contents().find('.js-reveal').addClass('js-revealed');
        },10)

        
        /**
         * Re-invoke JS
         */

        try{
            document.getElementById('d6-flex-iframe').contentWindow.allScripts(true);
        }
        catch(err){

        }

    }


    const addBlockToIframe = (block) => {


        let $container = getBlocksWrapper();

        

        let newBlockHtml = '<div class="blder-block-item">'+block.block_html+'<style class="section-styles-css">'+block.css+'</style></div>';

        if(insertNewBlockAfterBlock !== -1){

            $container.find('.blder-block-item').eq(insertNewBlockAfterBlock).after( newBlockHtml )
            
        } else {

            $container.append( newBlockHtml );
        }

        
        afterBlocksAdded();

    }



    const updateBlock = (block, index) => {

        let theBlock = JSON.parse(JSON.stringify(block))


        if ($(theBlock.block_html).find('img').length){
            setIsLoading(true)
            iframeImagesLoaded(theBlock.block_html);
        }
        

        let $container = getBlocksWrapper();


        let updatedBlockHtml = '<div class="blder-block-item">'+block.block_html+'<style class="section-styles-css">'+block.css+'</style></div>';


        $container.find('.blder-block-item').eq(index).replaceWith( updatedBlockHtml )

        
        afterBlocksAdded();

    }




    const addBlock = (block) => {

        
        let theBlock = JSON.parse(JSON.stringify(block))


        if ($(theBlock.block_html).find('img').length){
            setIsLoading(true)
            iframeImagesLoaded(theBlock.block_html);
        }

        
        /**
         * Update Page Blocks
         */

        let newPageBlocks = JSON.parse(JSON.stringify(pageBlocks));

        
        //we only need these props to save in the database
        let newBlock = {
            //block: theBlock.block,
            block_html: theBlock.block_html,
            block_json: theBlock.block_json,
            blockId: theBlock.blockId,
            css: theBlock.css,
            img: theBlock.img
        };


        if(insertNewBlockAfterBlock !== -1){

            //insert the new block new to the block on where the "+" button is clicked
            newPageBlocks.splice(insertNewBlockAfterBlock + 1, 0, newBlock);

        } 
        
        else{

            newPageBlocks.push(newBlock);
        }

        //setIframeHeight();


        setPageBlocks(newPageBlocks);
        setInsertNewBlockAfterBlock(-1);
        scrollToBlock(insertNewBlockAfterBlock);
        
        savePage({page_blocks: newPageBlocks})///


        addBlockToIframe({'block_html': block.block_html, 'css': block.css})
    }






    useEffect( () => { 

        setIsLoading(true)
      

        /**
         * Set iFrame Height
         */


        setIframeHeight();


        /**
         * Detect Iframe Click
         */
        
        
        window.addEventListener("blur", () => {
            setTimeout(() => {
              if (document.activeElement.tagName === "IFRAME") {
                  window.focus();
                  //setIframeHeight();
              }
            });
        });

        const addInitialBlocksToIframe = (blocks, loopCount) =>{


            let $container = getBlocksWrapper();


            

            if(!$container.length){


                if(loopCount>100){
                    /**
                     * Reload just incase it cannot find the container after 100 attemps
                     */
                    window.location.reload()
                    return;
                }

                (()=>{

                    setTimeout(()=>{

                        loopCount = loopCount + 1;
                        addInitialBlocksToIframe(blocks, loopCount)
                    },100)

                })(loopCount)
              

                return;
            }



            blocks.forEach((block,i)=>{

                let blockId = makeBlockId(10);
                block.blockId = blockId;
                addBlockToIframe(block)
            });
        }


        /**
         * Get Page 
         */


        const ourRequest = axios.CancelToken.source() // <-- 1st step

        const getPage = async () =>{

            await axios.get(process.env.REACT_APP_API_ENDPOINT + '/flex-page/' + projectId + '/'+ flexPageId, {
                cancelToken: ourRequest.token,
            })

            .then(res => {

                if(res.data.not_found){

                    navigate('/start/');

                } else{
                    
                    /**
                     * Lets empty the iframe when switching pages, 
                     */

                    let $container = getBlocksWrapper();
                    $container.remove();

                    
                    //setIframeHTML(res.data.page);
                    setPageBlankTemplate(res.data.page)
                    setPageBlocks(res.data.page_blocks);
                    setFlexPagePermalink(res.data.flex_page_permalink);
                    setSettings(res.data.settings);
                    setFonts(res.data.fonts);
                    setPages(res.data.pages);
                    addInitialBlocksToIframe(res.data.page_blocks, 0)
                    setForceIframeRender(Math.random())
                    
                }

            })

            .catch((error) => {

                //navigate('/start/');
            });

        }

        getPage();

        

        return () => {
            setForceIframeRender(Math.random())
            ourRequest.cancel(); //cancel axios request
        }

    // eslint-disable-next-line
    }, [flexPageId]);



   
    useEffect( () => { 
        
        pageBlocksForNonReactJs = pageBlocks
    // eslint-disable-next-line
    }, [pageBlocks]);

    
    return (
        <div className="page-wrap">

            
            {
                ( isLoading || isSaving || isUpdating) &&

                <div className="loading">
                    <div className="spinner-border text-primary" role="status"></div>
                </div>
            }

            <Sidebar pages={pages} setPages={setPages} settings={settings} setSettings={setSettings} fonts={fonts} flexPageId={flexPageId} previewClass={previewClass} setPreviewClass={setPreviewClass} addBlock={addBlock} flexPagePermalink={flexPagePermalink} addBlockButtonRef={addBlockButtonRef} pageBlocks={pageBlocks} reorderBlocks={reorderBlocks} projectId={projectId}/>

            <BlockEditor sectionToEdit={sectionToEdit} setSectionToEdit={setSectionToEdit} pageBlocks={pageBlocks} setPageBlocks={setPageBlocks} flexPageId={flexPageId} updateBlock={updateBlock}/>

            <div className={'preview-panel' + ( sectionToEdit > -1?' xxxwith-block-editor': '' )}>

                
                <Iframe setIsLoading={setIsLoading} previewClass={previewClass} pageBlankTemplate={pageBlankTemplate} forceIframeRender={forceIframeRender}></Iframe>

                {
                    !pageBlocks.length && !isLoading &&
                    <div className="add-blocks-btn" onClick={()=>addBlockButtonRef.current.click()}><span><img src={plus} alt=""/></span><div>Add Blocks</div></div>
                }

            </div>

            <Footer/>

        </div>
    );
  }
  
export default Page;