import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Page404 from './components/404';
import Page from './components/Page';
import Start from './components/Start';
import Home from './components/Home';
import Preview from './components/Preview';

import './assets/css/App.scss';

function App() {
  return (
      <div className="App">

        <BrowserRouter>

          <div>

            <Routes>

              <Route path="/" element={<Home />}/>
              <Route exact path="/404" element={<Page404 />}/>
              <Route exact path="/start" element={<Start />}/>
              <Route exact path="/projects/:projectId/:flexPageId" element={<Page />}/>
              <Route exact path="/preview" element={<Preview />}/>

            </Routes>

          </div>

        </BrowserRouter>

      </div>
  );
}


export default App;
