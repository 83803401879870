import React from "react";

//import axios from 'axios';
import {useEffect, useState, useRef} from "react"
import axios from 'axios';
import { Link, useNavigate } from "react-router-dom";

import editIcon from '../../assets/img/edit.svg'
import trashIcon from '../../assets/img/trash.svg'
import checkIcon from '../../assets/img/check.svg'
import cancelIcon from '../../assets/img/close-white.svg'

function SubMenuPages({pages, setPages, subMenu, hideSubMenu, flexPageId, projectId}) {

    const editTitleInputRef = useRef(null);
    const addPageInputRef = useRef(null);
    const navigate = useNavigate();

    const [isAddPage, setIsAddPage] = useState(false);
    const [newPageTitle, setNewPageTitle] = useState('');
    const [pageTitleToEdit, setPageTitleToEdit] = useState({});
    const [pageToDelete, setPageToDelete] = useState({});


    useEffect( () => { 

        if(editTitleInputRef.current !== null){
            editTitleInputRef.current.focus()
        }

    }, [editTitleInputRef, pageTitleToEdit.ID]);


    useEffect( () => { 

        if(addPageInputRef.current !== null){
            addPageInputRef.current.focus()
        }

    }, [addPageInputRef, isAddPage]);



   
    const createNewPage = () =>{


        if( ! newPageTitle.trim() ) {

            alert('Please enter a page title');

            return;
        }

        axios.post(process.env.REACT_APP_API_ENDPOINT + '/flex-new-page/' + projectId, {page_title: newPageTitle})

        .then(res => {

            setIsAddPage(false)
            setNewPageTitle('')
            setPages(res.data.pages)
            navigate('/projects/' + res.data.project_id + '/' + res.data.page_id + '/');
            

        })

        .catch((error) => {

        });

    }


    const setPageTitle = (value) => {

        let newPageTitleToEdit = JSON.parse(JSON.stringify(pageTitleToEdit));

        newPageTitleToEdit.title = value;

        setPageTitleToEdit(newPageTitleToEdit)

    }


    const savePageTitle = (index) => {


        if(pageTitleToEdit.title.trim()){

            let newPages = JSON.parse(JSON.stringify(pages));
            newPages[index] = pageTitleToEdit;
            setPages(newPages);
            setPageTitleToEdit({})

            axios.post(process.env.REACT_APP_API_ENDPOINT + '/flex-save-page-title/' + projectId, {flex_page_id: pageTitleToEdit.ID, title: pageTitleToEdit.title})

            .then(res => {

                
            })

            .catch((error) => {

            });
        }

    }


    const deletePage = (index) => {

        if(pageToDelete){

            

            axios.post(process.env.REACT_APP_API_ENDPOINT + '/flex-delete-page/' + projectId, {flex_page_id: pageToDelete.ID})

            .then(res => {
                
            })

            .catch((error) => {

            });




            let newPages = JSON.parse(JSON.stringify(pages));
            
            newPages.splice(index, 1);
            setPages(newPages);

            if(parseInt(pageToDelete.ID) === parseInt(flexPageId)){
                navigate('/projects/' + projectId + '/' + newPages[0].ID + '/');
            }


            setPageToDelete({})
        }

    }



    return (

        <div className={`style-menu crt-sub-menu ${subMenu === 4?'show':''}`} id="pages-menu">

            <div className="crt-inner-content">

                <div className="crt-sub-menu-title">Project Pages</div>

                <div className="crt-inner-content-wrap">
                    
                    <ul className="pages">
                        {pages.map((page,i)=>{
                            return <li key={i} className={`${parseInt(flexPageId)===parseInt(page.ID)?' active':''} ${parseInt(pageTitleToEdit.ID) === parseInt(page.ID)?' edit':''}`}>
                                    {
                                        parseInt(pageTitleToEdit.ID) === parseInt(page.ID)?
                                        <>
                                            <input type="text" 
                                                placeholder="Enter Page Title" 
                                                value={pageTitleToEdit.title} 
                                                ref={editTitleInputRef}
                                                onChange={(e)=>{
                                                    setPageTitle(e.target.value)
                                                }}
                                                onKeyDown={(e)=>{
                                                    if (e.key === 'Enter') {

                                                        savePageTitle(i)
                                                    }
                                                }}
                                                onBlur={(e)=>{
                                                    savePageTitle(i)
                                                }}
                                            />
                                        </>
                                        :
                                        <>
                                            <Link to={page.url}>{page.title}</Link>
                                            

                                            {
                                                parseInt(pageToDelete.ID) === parseInt(page.ID) ?
                                                    <>
                                                        <button className="act1 close" onClick={()=>{ setPageToDelete({});}}><img src={cancelIcon} alt="" /></button>
                                                        <button className="act2" onClick={()=>{ deletePage(page);}}><img src={checkIcon} alt="" /></button>
                                                    </>
                                                :
                                                <>  
                                                    {
                                                        pages.length > 1 &&
                                                        <button className="act1" onClick={()=>{ setPageToDelete(page);}}><img src={trashIcon} alt="" /></button>
                                                    }
                                                    
                                                    <button className="act2" onClick={()=>{ setPageTitleToEdit(page);}}><img src={editIcon} alt="" /></button>
                                                </>
                                                
                                            }
                                        </>

                                    }
                                    
                                </li>
                        })}
                    </ul>
                    


                    {
                        isAddPage ?
                        <div className="crt-field-group">
                            <input type="text" 
                                placeholder="Enter Page Title" 
                                ref={addPageInputRef} 
                                value={newPageTitle} 
                                onChange={(e)=>{setNewPageTitle(e.target.value)}}
                                onBlur={(e)=>{
                                    //setIsAddPage(false)
                                }}
                                onKeyDown={(e)=>{
                                    
                                    if (e.key === 'Escape') {
                                        setIsAddPage(false)
                                    } else if (e.key === 'Enter') {
                                        createNewPage()
                                    }
                                }}
                            />
                            <button style={{marginTop:'10px'}} className="btn" onClick={()=>{createNewPage()}}>Add</button>
                            
                        </div>
                        :
                        <button className="btn" onClick={()=>{setIsAddPage(true)}}>Add New Page</button>

                    }

                    
                </div>
            </div>

            <div className="crt-menu-overlay" onClick={()=>hideSubMenu()}></div>

        </div>

    );
}


export default SubMenuPages;