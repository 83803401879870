import React from "react";
//import { withRouter } from "react-router-dom";


function Home() {
    return (
        <div className="page-wrap">

            <div className="text-center" style={{padding: '50px 0'}}>
                <h2>Home</h2>
            </div>

        </div>
    );
  }
  
export default Home;