import React from "react";
//import { withRouter } from "react-router-dom";


function Preview() {
    return (
        <div className="page-wrap">

            <div className="text-center" style={{padding: '50px 0'}}>
                <h2>Preview</h2>
            </div>

        </div>
    );
  }
  
export default Preview;