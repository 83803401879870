import React from "react";

//import axios from 'axios';
import {useEffect} from "react"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";



// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};


const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    //userSelect: "none",

    // styles we need to apply on draggables
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({

});


const getListClass = isDraggingOver => (isDraggingOver ? "reorder-dragging reorder-container" : "reorder-container");
const getItemClass = isDraggingOver => (isDraggingOver ? "item-dragging" : "");





function SubMenuReorderBlocks({subMenu, hideSubMenu, pageBlocks, reorderBlocks}) {


    


    const onDragEnd = (result) => {

        // dropped outside the list

        let newPageBlocks = JSON.parse(JSON.stringify(pageBlocks));

        if (!result.destination) {
            return;
        }


        const blocks = reorder(
            newPageBlocks,
            result.source.index,
            result.destination.index
        );


        //console.log(blocks)

        //setState({blocks});

        reorderBlocks(blocks, result.source.index,  result.destination.index);
    }



    useEffect( () => { 


    }, []);


    


    return (

        <div className={`reorder-blocks-menu crt-sub-menu ${subMenu === 3?'show':''}`} id="reorder-blocks-menu">

            <div className="crt-inner-content">

                <div className="crt-sub-menu-title">Reorder Blocks</div>

                <div className="crt-inner-content-wrap">
                    
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    className={getListClass(snapshot.isDraggingOver)}
                                    style={getListStyle(snapshot.isDraggingOver)}
                                >
                                    {pageBlocks.map((block, index) => (
                                        <Draggable key={'block-'+index} draggableId={'block-'+index} index={index}>
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    className={getItemClass(snapshot.isDragging)}
                                                    style={getItemStyle(
                                                        snapshot.isDragging,
                                                        provided.draggableProps.style
                                                    )}
                                                >
                                                    <img src={block.img} alt=""/>
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>
            </div>
            <div className="crt-menu-overlay" onClick={()=>hideSubMenu()}></div>
        </div>


    );
}


export default SubMenuReorderBlocks;