import React from "react";
//import { withRouter } from "react-router-dom";
import {useEffect} from "react"
import $ from 'jquery';




function Iframe({previewClass, pageBlankTemplate, setIsLoading, forceIframeRender}) {


    const iframeOnload = (newIframeElement) => {


        newIframeElement.onload = function() {


            /**
             * remove style#d6-block-inline-css because we are injecting each blocks css below it
             */

            $('#d6-flex-iframe').contents().find('#d6-block-inline-css').remove()

            /**
             * 
             */

            
            if( ! $('#d6-flex-iframe').contents().find('body').find('#d6-flex-controls-css').length){
                $('#d6-flex-iframe').contents().find('body').append('<style id="d6-flex-controls-css">'+controlsCSS+'</style>')
            }


            setIsLoading(false);
        };
    }


    const setIframeHTML = (pageBlankTemplate) => {


        let newIframeElement = document.getElementById('d6-flex-iframe');

        if(!newIframeElement || !pageBlankTemplate) return;


        
        newIframeElement.src = "about:blank";
        newIframeElement.contentWindow.document.open();
        //newIframeElement.contentWindow.document.write('<html><body>yy</body></html>');
        newIframeElement.contentWindow.document.write(pageBlankTemplate);
        newIframeElement.contentWindow.document.close();


        
        iframeOnload(newIframeElement);



        /**
         * Prevent links clickable and form submit
         */

        $('#d6-flex-iframe').contents().on('click', 'a',function (e) {
            e.preventDefault();
            return false;
        });


        $('#d6-flex-iframe').contents().on('submit', 'form',function (e) {
            e.preventDefault();
            return false;
        });

        
        
        
    }



    useEffect(() => {

        var container = document.querySelector('.iframe-container');
        container.innerHTML = '';

        var iframe = document.createElement("iframe");
        iframe.setAttribute("id", "d6-flex-iframe");
        iframe.setAttribute("scrolling", (previewClass === 'tablet' || previewClass === 'mobile'?'yes':'no'));
        iframe.className = previewClass;
        document.body.appendChild(iframe);
        
        container.appendChild(iframe);


        setIframeHTML(pageBlankTemplate);


        return () => {

            container.innerHTML = '';
        }

    // eslint-disable-next-line
    }, [forceIframeRender]);


    useEffect(() => {


        $('#d6-flex-iframe').removeClass('tablet mobile desktop');

        $('#d6-flex-iframe').addClass(previewClass);

    // eslint-disable-next-line
    }, [previewClass]);


    return (
        <div className="iframe-container"></div>
    );
  }
  
export default Iframe;




const controlsCSS = `
.blder-block-item {
    position:relative;
}
.blder-block-item, .blder-block-item *{
    box-sizing: border-box;
}

.blder-block-item .d6f-block-control{
    top: 10px;
    right: 10px;
    bottom: 10px;
    position: absolute;
    opacity: 0;
    visibility: hidden;
    z-index: 10000000;
    pointer-events: none;
}

.blder-block-item .d6f-block-control span{
    cursor: pointer;
    display: block;
    width: 30px;
    height: 30px;
    box-shadow: 0 0 8px 0 rgba(0,0,0,.15);
    background: #FFF;
    padding: 10px;
    line-height: 0;
    border-radius: 50%;
    background-size: 40%;
    background-position: center;
    background-repeat: no-repeat;
    margin-bottom:5px;
    transition: all 0.5s cubic-bezier(0.5, 0.005, 0.075, 0.985);
    transform: translateX(50px);
    
}

.blder-block-item .d6f-block-control span.d6f-remove-block{
    background-image: url(/images/close.svg);
    pointer-events: auto;
}

.blder-block-item .d6f-block-control span.d6f-move-up-block{
    background-image: url(/images/arrow-up.svg);
    pointer-events: auto;
}

.blder-block-item .d6f-block-control span.d6f-move-down-block{
    background-image: url(/images/arrow-down.svg);
    pointer-events: auto;
}

.blder-block-item .d6f-block-control span.d6f-edit-block{
    background-image: url(/images/edit.svg);
    pointer-events: auto;
}

.blder-block-item .d6f-block-control span.d6f-insert-block{
    background-image: url(/images/add-block.svg);
    pointer-events: auto;
    bottom: -10px;
    position: absolute;
    right: 50vw;
    margin-right: -25px;
    transform: translateY(50px);
}

.blder-block-item .d6f-block-control span svg{
    width: 100%;
    display: inline-block;
    height: auto;
}

.blder-block-item:first-child .d6f-block-control span.d6f-move-up-block{
    display: none;
}

.blder-block-item:last-child .d6f-block-control span.d6f-move-down-block{
    display: none;
}

.blder-block-item>section:hover{
    -webkit-box-shadow:inset 0px 0px 0px 1px #0091ff;
    -moz-box-shadow:inset 0px 0px 0px 1px #0091ff;
    box-shadow:inset 0px 0px 0px 1px #0091ff;
}

.blder-block-item:hover .d6f-block-control{
    
    opacity: 1;
    visibility: visible;
    
}

.blder-block-item:hover .d6f-block-control span{
    transform: translateX(0);
}

.blder-block-item .d6f-active-edit{
    -webkit-box-shadow:inset 0px 0px 0px 1px red!important;
    -moz-box-shadow:inset 0px 0px 0px 1px red!important;
    box-shadow:inset 0px 0px 0px 1px red!important;
}

`;
